<template>
  <div>
    <v-row>
      <v-col :cols="12" :md="4" class="pb-0">
        <v-text-field
          v-model="form.number"
          :rules="[v => !!v || 'Asset number is required']"
          label="Asset Number"
          outlined
          required
          dense
          autofocus
        />
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col :cols="12">
        <h2>Basic Information</h2>
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.manafacturer"
          :rules="[v => !!v || 'Manafacturer is required']"
          label="Manufacturer"
          outlined
          required
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.model"
          :rules="[v => !!v || 'Model is required']"
          label="Model"
          outlined
          required
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.colour"
          label="Colour"
          outlined
          required
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-select
          v-model="form.type"
          :items="types"
          :rules="[v => !!v || 'Type is required']"
          label="Type"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.estimated_purchase_value"
          :step="0.01"
          type="number"
          prefix="£"
          label="Estimated Purchase Value"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col :cols="12">
        <h2>Gear Information</h2>
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.speed"
          :step="1"
          :min="6"
          :max="12"
          type="number"
          label="Speed (gears)"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.gear_manafacturer"
          label="Gear Manafacturer"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.gear_model"
          label="Gear Model"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-select
          v-model="form.gear_type"
          :items="gearTypes"
          label="Gear Type"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col :cols="12">
        <h2>Brake Information</h2>
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.brake_manafacturer"
          label="Brake Manafacturer"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.brake_model"
          label="Brake Model"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-select
          v-model="form.brake_type"
          :items="brakeTypes"
          label="Brake Type"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col :cols="12">
        <h2>Wheel Information</h2>
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-select
          v-model="form.wheel_size"
          :items="wheelSizes"
          label="Wheel Size"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col :cols="12">
        <h2>Service Data</h2>
      </v-col>
      <v-col :cols="12" :md="3" class="pb-0">
        <v-text-field
          v-model="form.service_life_years"
          :step="1"
          :min="1"
          :max="100"
          type="number"
          label="Estimated Service Life (Years)"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="12" :md="3">
        <v-dialog
          ref="purchasedOnDateDialog"
          v-model="purchasedOnDateDialog"
          :return-value.sync="form.purchased_on"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="$moment(form.purchased_on).format('ddd Do MMM')"
              label="Purchased On Date"
              readonly
              clearable
              outlined
              dense
              v-on="on"
            />
          </template>
          <v-date-picker
            scrollable
            v-model="form.purchased_on"
            :max="$moment().add(1, 'day').format('YYYY-MM-DD')"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="purchasedOnDateDialog = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.purchasedOnDateDialog.save(form.purchased_on)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col :cols="12" :md="3">
        <v-dialog
          ref="enteredServiceDateDialog"
          v-model="enteredServiceDateDialog"
          :return-value.sync="form.entered_service_on"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="$moment(form.entered_service_on).format('ddd Do MMM')"
              label="Entered Service Date"
              readonly
              clearable
              outlined
              dense
              v-on="on"
            />
          </template>
          <v-date-picker
            scrollable
            v-model="form.entered_service_on"
            :max="$moment().add(1, 'day').format('YYYY-MM-DD')"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="enteredServiceDateDialog = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.enteredServiceDateDialog.save(form.entered_service_on)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col :cols="12" :md="3">
        <v-dialog
          ref="decommisionedOnDateDialog"
          v-model="decommisionedOnDateDialog"
          :return-value.sync="form.decommisioned_on"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="$moment(form.decommisioned_on).format('ddd Do MMM')"
              label="Decommisioned On Date"
              readonly
              clearable
              outlined
              dense
              v-on="on"
            />
          </template>
          <v-date-picker
            scrollable
            v-model="form.decommisioned_on"
            :min="$moment().add(1, 'day').format('YYYY-MM-DD')"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="decommisionedOnDateDialog = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.decommisionedOnDateDialog.save(form.decommisioned_on)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HasFormProp from '@/mixins/HasFormProp'

export default {
  name: 'AssetFormFields',

  mixins: [ HasFormProp ],

  data () {
    return {
      types: [],
      gearTypes: [],
      brakeTypes: [],
      wheelSizes: [],
      enteredServiceDateDialog: false,
      purchasedOnDateDialog: false,
      decommisionedOnDateDialog: false
    }
  },

  mounted () {
    this.$api.get('bike-types')
      .then(types => {
        Object.keys(types).forEach(key => {
          this.types.push({
            text: types[key],
            value: key
          })
        })
      })
    this.$api.get('bike-gear-types')
      .then(types => {
        Object.keys(types).forEach(key => {
          this.gearTypes.push({
            text: types[key],
            value: key
          })
        })
      })
    this.$api.get('bike-brake-types')
      .then(types => {
        Object.keys(types).forEach(key => {
          this.brakeTypes.push({
            text: types[key],
            value: key
          })
        })
      })
    this.$api.get('bike-wheel-sizes')
      .then(sizes => {
        Object.keys(sizes).forEach(key => {
          this.wheelSizes.push({
            text: sizes[key],
            value: key
          })
        })
      })
  }
}
</script>

<style lang="css" scoped>
</style>