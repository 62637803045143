<template>
  <v-container>
    <h1>New Asset {{ form.number ? `- ${form.number}` : null }}</h1>

    <v-row>
      <v-col :cols="12">
        <v-card tile>
          <v-card-text>
            <v-form
              ref="form"
              v-model="validForm"
            >
              <AssetFormFields
                :form="form"
              />

              <FormErrors ref="formErrors" />

              <v-btn
                color="primary"
                depressed
                :loading="loading"
                @click="createAsset"
              >
                Create Asset
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar success ref="successBar">
      Asset created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the asset.
    </SnackBar>
  </v-container>
</template>
<script>
import HasForm from '@/mixins/HasForm'
import AssetFormFields from '@/components/assets/AssetFormFields'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'NewAsset',

  mixins: [ HasForm ],

  components: {
    AssetFormFields,
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false
    }
  },

  mounted () {
    this.$set(this.form, 'assetable_type', 'App\\Asset')
    this.$set(this.form, 'assetable_id', this.$auth.account.id)
  },

  methods: {
    createAsset () {
      this.loading = true
      
      this.$api.post('assets', this.form)
        .then(({ data: asset }) => {
          this.$refs.successBar.open()
          this.$router.push({ name: 'assets.show', params: { assetId: asset.id } })
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="css" scoped>
</style>